import React from 'react';
import './Card.scss';
import CountUp from 'react-countup';

const Card = ({
    title,
    value,
    unit
}) => {
    return (
        <div className="card">
            <div className="card-body">
                <p className="card-title font-semibold m-0">{title}</p>
                <div className="card-value">
                    <div className='d-flex'>
                        <div className="m-0 font-semibold primary-color">
                            <CountUp end={value} duration={2} />
                        </div>
                        <span className='font-size-small pl-2 font-semibold'>{unit}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Card;