import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import 'fontsource-roboto';
import { BrowserRouter, Router } from 'react-router-dom';
import routes from './constants/routes.json';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import history from './history';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#99383b'
    },
    secondary: {
      main: '#FFFFFF'
    }
  },
});

if(!localStorage.getItem('token')) {
  history.push(routes.ROOT);
}
if(localStorage.getItem('token') && window.location.pathname == '/') {
  history.push(routes.DASHBOARD);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
          </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
