import React from 'react';
import './Footer.scss';

class Footer extends React.Component {

    render() {
        return(
            <>
                <div className="footer-wrapper d-flex justify-center align-center">
                    <span className="font-size-small">2021 © Cherokee Nation</span>
                </div>
            </>
        )
    }
}

export default Footer;