import { SET_TOTAL_TIME } from '../actions/action-types';

const initialState = {
    totalTime: 0
};

const gameTime = (state = initialState, action) => {
	switch (action.type) {
	case SET_TOTAL_TIME:
		return Object.assign({},
			action.payload);
	default:
		return state;
	}
};

export default gameTime;