import { SET_STUDENTS_INFO, SET_ACTIVE_PAGE } from '../actions/action-types';

const initialState = {
    activePage: 1,
    students: [],
    totalStudents: 0
}

const studentsData = (state = initialState, action) => {
	switch (action.type) {
        case SET_STUDENTS_INFO:
            return Object.assign({},
                action.payload);
        case SET_ACTIVE_PAGE:
            return Object.assign({},
                action.payload)
        default:
            return state;
	}
};

export default studentsData;