import React from 'react';
import { connect } from 'react-redux';
import { setIsLoggedIn } from '../redux/actions/session-actions';
import routes from '../constants/routes.json';

class Logout extends React.Component {

    componentDidMount() {
        this.props.dispatch(setIsLoggedIn({isLoggedIn: false}))
        localStorage.clear();
        this.props.history.push(routes.LOGIN);
    }

    render() {
        return(
            <>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        session: state.session
    }
}

export default connect(mapStateToProps)(Logout);