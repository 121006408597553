import { Switch, Route, Redirect } from 'react-router-dom';
import routes from '../constants/routes.json';
import Dashboard from '../pages/Dashboard';
import Students from '../pages/Students';
import Configuration from '../pages/Configuration';
import Auth from '../pages/Auth';
import Login from '../pages/Login';
import history from '../history';
import Logout from '../pages/Logout';
import Landing from '../pages/Landing';


function Routes() {

  return (
      <>
        <Switch>
          <Route path={routes.ROOT} component={Landing} exact />
          <Route path={routes.AUTH} component={Auth} exact />
          <Route path={routes.DASHBOARD} component={Dashboard} exact />
          <Route path={routes.CONFIGURATION} component={Configuration} exact />
          <Route path={routes.STUDENTS} component={Students} exact />
          <Route path={routes.LOGIN} component={Login} exact />
          <Route path={routes.LOGOUT} component={Logout} exact />
        </Switch>
      </>
  )
}

export default Routes;