import { SET_GAME_INFO } from '../actions/action-types';

const initialState = {
	totalGameplays: 0,
	highScore: 0
};

const gameInfo = (state = initialState, action) => {
	switch (action.type) {
	case SET_GAME_INFO:
		return Object.assign({},
			action.payload);
	default:
		return state;
	}
};

export default gameInfo;