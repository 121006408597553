import React from 'react';
import { connect } from 'react-redux';
import { BASE_URL } from '../constants/AppConstants';
import { setStudentsInfo, setActivePage } from '../redux/actions/students-actions';
import StudentsTable from '../components/students-table/StudentsTable';
import { setLoading } from '../redux/actions/common-actions';
import { APIService } from '../services/ApiService';
import { Pagination } from '@material-ui/lab';
import './Students.scss';

class Students extends React.Component {

    componentDidMount() {
        this.loadStudents(1);
    }

    async loadStudents(page) {
        this.props.dispatch(setLoading(true))
        APIService.get(BASE_URL, `players?page=${page}&size=10`)
            .then(
                (result) => {
                    this.props.dispatch(setLoading(false));
                    this.props.dispatch(setStudentsInfo(result)); 
                }
            )
    }

    async handlePageChange(event, pageNumber) {
        await this.loadStudents(pageNumber);
        await this.props.dispatch(setActivePage(pageNumber));
    }

    render() {
        return(
            <>
                <div className='students-wrapper py-3 container'>
                    <StudentsTable students={this.props.students.students} />
                    <div className='pt-2 float-right'>
                        <Pagination
                            defaultPage={1}
                            color="primary"
                            count={Math.ceil(this.props.students.totalStudents/10)} 
                            onChange={this.handlePageChange.bind(this)}
                        />
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        students: state.students,
        loader: state.loader
    }
}

export default connect(mapStateToProps)(Students);