import React, { Component } from 'react';
import navBarStyles from './navbar.module.scss';
import { NavLink, withRouter } from 'react-router-dom';
import routes from '../../constants/routes.json';
import logo from '../../assets/logo.svg';
import { connect } from 'react-redux';
import Sidenav from '../sidenav/Sidenav';

class NavBar extends Component {

  render() {
    return (
      <div className={`${navBarStyles.navbar} navbar-wrapper `}>
        {
            <Sidenav />
        }
        <div className="container d-flex">
          <img onClick={() => {this.props.history.push(routes.ROOT)}} className={navBarStyles.logo} alt='logo' src={logo} />
          <div className='d-flex w-100 justify-right navbar-list'>
            {
              (this.props.session.isLoggedIn) ? (
                <>
                  <NavLink to={routes.DASHBOARD} activeClassName={`${navBarStyles.linkActive}`} className={`${navBarStyles.link}`}> Dashboard </NavLink>
                  <NavLink to={routes.STUDENTS} activeClassName={`${navBarStyles.linkActive}`} className={`${navBarStyles.link}`}> Students </NavLink>
                  <NavLink to={routes.CONFIGURATION} activeClassName={`${navBarStyles.linkActive}`} className={`${navBarStyles.link}`}> Configuration </NavLink>
                  <NavLink to={routes.LOGOUT} activeClassName={`${navBarStyles.linkActive}`} className={`${navBarStyles.link}`}> Logout </NavLink>
                </>
              ) : null
            }
          </div>
          {
            (!this.props.session.isLoggedIn) ? (
              <div className='d-flex w-100 justify-right admin-login'>
              <NavLink to={routes.LOGIN} activeClassName={`${navBarStyles.linkActive}`} className={`${navBarStyles.link}`}> LOGIN AS ADMIN</NavLink>
            </div>
            ) : null
          }
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
      session: state.session
  }
}

export default withRouter(connect(mapStateToProps)(NavBar));
