import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import routes from '../../constants/routes.json';
import { useHistory } from "react-router";
import MenuIcon from '@material-ui/icons/Menu';
import './Sidenav.scss';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  list: {
    width: 250,
    paddingTop: 30,
    color: 'white'
  },
  fullList: {
    width: 'auto',
  },

});

export default function Sidenav() {
  const history = useHistory();
  const classes = useStyles();
  let isLoggedIn = useSelector(state => state.session.isLoggedIn);
  const [state, setState] = React.useState({
    left: false
  });

  const left = 'left';
  const routeDetails = [
    {
      name: 'Dashboard',
      route: routes.DASHBOARD
    },
    {
      name: 'Students',
      route: routes.STUDENTS
    },
    {
      name: 'Configurations',
      route: routes.CONFIGURATION
    },
    {
      name: 'Logout',
      route: routes.LOGOUT
    }
  ]

  const toggleDrawer = (left, open) => (event) => {
    console.log('toggle');
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [left]: open });
  };

  const navigate = (route) => (event) => {
    history.push(route);
  }

  const list = (left) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: left === 'top' || left === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(left, false)}
      onKeyDown={toggleDrawer(left, false)}
    >
      <List>
        {
          isLoggedIn ? (
            routeDetails.map((route, index) => (
              <ListItem button key={route.name} onClick={navigate(route.route)}>
                <ListItemText primary={route.name} />
              </ListItem>
            ))
          ) : (
            <>
              <ListItem button key={routes.LOGIN} onClick={navigate(routes.LOGIN)}>
                <ListItemText>LOGIN AS ADMIN</ListItemText>
              </ListItem>
            </>
          )
        }
      </List>
    </div>
  );

  return (
    <div className="sidenav-wrapper">
          <>
            {
                <>
                  <MenuIcon color="secondary" className="mr-4 burger-icon" onClick={toggleDrawer(left, true)}/>
                    <SwipeableDrawer
                    bac
                    left={left}
                    open={state[left]}
                    onClose={toggleDrawer(left, false)}
                    onOpen={toggleDrawer(left, true)}
                >
                    {list(left)}
                </SwipeableDrawer>
                </>
            }
          </>
    </div>
  );
}