import React from 'react';
import Loader from 'react-loader-spinner';

const LoaderComponent = (isLoading) => {
    return (
        <Loader
            type="ThreeDots"
            color="#99383b"
            height={50}
            width={50}
            visible={isLoading.isLoading}
          />
    )
}
export default LoaderComponent;