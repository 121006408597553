import { SET_GAME_INFO, SET_TOTAL_TIME } from "./action-types"

export const setGameInfo = (data) => {
    return({
        type: SET_GAME_INFO,
        payload: { totalGameplays: data.totalGameplays, highScore: data.highScore }
    })
}

export const setTime = (data) => {
    return({
        type: SET_TOTAL_TIME,
        payload: { totalTime: data }
    })
}