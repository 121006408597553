import { combineReducers } from 'redux';
import dashboard from './dashboard-reducer';
import students from './students-reducer';
import session from './session-reducer';
import loader from './loader-reducer';
import gameTime from './gameTime-reducer';

export default combineReducers({
	dashboard,
	students,
	session,
	loader,
	gameTime
});