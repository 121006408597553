import { SET_STUDENTS_INFO, SET_ACTIVE_PAGE } from "./action-types"

export const setStudentsInfo = (data) => {
    return({
        type: SET_STUDENTS_INFO,
        payload: {
            students: data.rows,
            totalStudents: data.count
        }
    })
}

export const setActivePage = (data) => {
    return({
        type: SET_ACTIVE_PAGE,
        payload: {activePage: data}
    })
}