import React from 'react';
import { withRouter } from 'react-router-dom';
import { BASE_URL, MOODLE_LOGIN_URL } from '../constants/AppConstants';
import { APIService } from '../services/ApiService';
import moodle from '../constants/moodle.json';
import history from '../history';
import routes from '../constants/routes.json';

class Auth extends React.Component {

    componentDidMount() {
        this.authenticate();
    }

    async authenticate() {
        let params = new URLSearchParams(window.location.search);
        let code = params.get('code');
        if(!localStorage.getItem('ACCESS_TOKEN')) {
            if(code) {
                let body = {
                    code: code,
                    client_id: moodle.client_id,
                    client_secret: moodle.client_secret,
                    grant_type: 'authorization_code', 
                    scope: 'user_info'
                }
                const auth = await APIService.post(BASE_URL, 'moodle/auth',body );
                if (auth.access_token) {
                    localStorage.setItem('ACCESS_TOKEN', auth.access_token);
                    localStorage.setItem('REFRESH_TOKEN', auth.refresh_token);
                }
                this.props.history.push(routes.DASHBOARD);
            } else {
                window.location =  MOODLE_LOGIN_URL
            }
        } else {
            this.props.history.push(routes.DASHBOARD);
        }
    }

    render() {
        return (
            <>
                <p>Please wait while we redirect.</p>
            </>
        )
    }
}

export default withRouter(Auth);