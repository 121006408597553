import React from 'react';
import './Landing.scss';
import ipad from '../assets/ipad.png';
import mac from '../assets/macbook.png';
import appstore from '../assets/app-store-download.svg';
import macBuild from '../assets/InageiAnelvdodiMacOSX.pkg';

class Landing extends React.Component {

    componentDidMount() {
    }

    appStore() {
        alert('Coming soon in App Store');
    }

    render() {
        return(
            <>
                <div className="landing-wrapper">
                    <div className="download-wrapper h-100">
                        <div className="left-card">
                            <div className="w-100 d-flex flex-column align-center pb-5">
                                <p className="download">DOWNLOAD INAGEI ADVENTURE FOR</p>
                                <p className="device">iPads</p>
                                <div className="device-image pt-5">
                                    <img alt="ipad" src={ipad} />
                                </div>
                                <img className="pt-5 app-store-logo" onClick={this.appStore}  src={appstore} />
                            </div>
                        </div>
                        <div className="divider"></div>
                        <div className="right-card">
                        <div className="w-100 d-flex flex-column align-center pb-5">
                                <p className="download">DOWNLOAD INAGEI ADVENTURE FOR</p>
                                <p className="device">Mac or Windows PC</p>
                                <div className="device-image pt-5">
                                    <img alt="ipad" src={mac} />
                                </div>
                                <div className="mac-button pt-5">
                                    {
                                        (window.navigator.platform.match(/^Mac/)) ? (
                                            <a href={macBuild}>Download for MAC OS X</a>
                                        ) : <a>Download for WINDOWS</a>
                                    }
                                </div>
                                <div className="windows-download pt-5">
                                    {
                                        (window.navigator.platform.match(/^Mac/)) ? (
                                            <>
                                                <p className="text-center">NOT ON A MAC ?</p>
                                                <p className="m-0"><a>Download for Windows</a></p>
                                            </>
                                        ) : (
                                            <>
                                                <p className="text-center">NOT ON WINDOWS ?</p>
                                                <p className="m-0"><a>Download for Mac</a></p>
                                            </>
                                        )
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


export default Landing;