import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { BASE_URL } from '../../constants/AppConstants';
import { APIService } from '../../services/ApiService';
import './StudentsTable.scss';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    table: {
        minWidth: 650,
      },
  }));

export default function StudentsTable() {
  const classes = useStyles();
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  
  let students = useSelector(state => state.students.students);

  const search = (e) => {
    if(e.target.value.length > 2) {
        setIsSearching(true);
        APIService.get(BASE_URL, `players/search?key=${e.target.value}`)
            .then(
                (result) => {
                    setSearchResults(result);
                }
            )
    } else if(e.target.value.length === 0) {
        setIsSearching(false);
    }
  }

  return (
      <>
        <div className="student-table-wrapper d-flex">
            <form className={classes.root} noValidate autoComplete="off">
                <TextField id="standard-basic" label="Search" variant="outlined" size="small" onChange={search} />
            </form>
        </div>
        <div className='students-table'>
            <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
            <TableHead>
                    <TableRow>
                        <TableCell align="center">#</TableCell>
                        <TableCell align="center">Username</TableCell>
                        <TableCell align="center">Student Name</TableCell>
                        <TableCell align="center">Language</TableCell>
                        <TableCell align="center">Game Time in Hours</TableCell>
                        <TableCell align="center">Game Plays </TableCell>
                        <TableCell align="center">Highscore</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {
                    !isSearching ? (
                    students && students.map((row, index) => (
                        <TableRow key={index+1}>
                        <TableCell align="center">{index+1}</TableCell>
                        <TableCell align="center">{row.username}</TableCell>
                        <TableCell component="th" scope="row" align="center">
                            {row.firstName} {row.lastName}
                        </TableCell>
                        <TableCell align="center">{row.language}</TableCell>
                        <TableCell align="center">{Math.round(row.playerGameLevel.totalTime/3600)}</TableCell>
                        <TableCell align="center">{row.playerGameLevel.totalGamePlays}</TableCell>
                        <TableCell align="center">{row.playerGameLevel.highScore}</TableCell>
                        </TableRow>
                    ))
                    ) :  searchResults && searchResults.map((row, index) => (
                        <TableRow key={index+1}>
                        <TableCell align="center">{index+1}</TableCell>
                        <TableCell align="center">{row.username}</TableCell>
                        <TableCell component="th" scope="row" align="center">
                            {row.firstName} {row.lastName}
                        </TableCell>
                        <TableCell align="center">{row.language}</TableCell>
                        <TableCell align="center">{Math.round(row.playerGameLevel.totalTime/3600)}</TableCell>
                        <TableCell align="center">{row.playerGameLevel.totalGamePlays}</TableCell>
                        <TableCell align="center">{row.playerGameLevel.highScore}</TableCell>
                        </TableRow>
                    ))  
                }
                </TableBody>
                {
                    (isSearching && searchResults && searchResults.length === 0) || (!isSearching && students && students.length === 0) ? (
                        <caption className="text-center">No records found</caption>
                    ) : null
                }
            </Table>
            </TableContainer>
        </div>
    </>
  );
}
