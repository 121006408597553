import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { BASE_URL } from '../../constants/AppConstants';
import { useDispatch } from 'react-redux';
import { setTime } from '../../redux/actions/dashboard-actions';
import { APIService } from '../../services/ApiService';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function LeaderTable() {
  const classes = useStyles();
  const [gameActivityInfo, setGameActivityInfoState] = useState([]);
  const dispatch = useDispatch();

  const init = async () => {
    await APIService.get(BASE_URL, 'game/players?gameId=1')
    .then((res) => {
        setGameActivityInfoState(res.rows);
        let totalHours = 0;
        res.rows.map((game) => {
          totalHours = totalHours + Math.round((game.totalTime)/3600);
        });
        dispatch(setTime(totalHours));
    })
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Rank</TableCell>
            <TableCell>Student Name</TableCell>
            <TableCell align="center">Current Level</TableCell>
            <TableCell align="center">Total Gameplays</TableCell>
            <TableCell align="right">High Score</TableCell>
            <TableCell align="center">Total Time taken</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            (gameActivityInfo.length > 0) ? (
              gameActivityInfo.map((row,index) => (
                (row.highScore > 0) ? (
                  <TableRow key={row.id}>
                  <TableCell align="center">#{index+1}</TableCell>
                  <TableCell component="th" scope="row">
                    {row.player.firstName} {row.player.lastName}
                  </TableCell>
                  <TableCell align="center">{row.gameLevel.levelName}</TableCell>
                  <TableCell align="center">{row.totalGameplays}</TableCell>
                  <TableCell align="right">{row.highScore}</TableCell>
                  <TableCell align="center">{Math.round((row.totalTime)/3600)}</TableCell>
                </TableRow>
                ) : null
              ))
            ) : null
          }
        </TableBody>
        {
          (gameActivityInfo.length === 0) ? (
            <caption className="text-center">No records found</caption>
          ) : null
        }
      </Table>
    </TableContainer>
  );
}
