import React from 'react';
import Card from '../components/shared/Card';
import { connect } from 'react-redux';
import { BASE_URL } from '../constants/AppConstants';
import { setGameInfo } from '../redux/actions/dashboard-actions';
import LeaderTable from '../components/leader-table/LeaderTable';
import { setLoading } from '../redux/actions/common-actions';
import { withRouter } from 'react-router-dom';
import { APIService } from '../services/ApiService';

class Dashboard extends React.Component {

    componentDidMount() {
        this.props.dispatch(setLoading(true));
        APIService.get(BASE_URL, 'game/activity?gameId=1')
            .then(
                (result) => {
                    this.props.dispatch(setLoading(false));
                    this.props.dispatch(setGameInfo(result));
                }
            )
    }

    render() {
        return(
            <>
                <div className='dashboard-wrapper container py-3'>
                    <div className='title-wrapper font-semibold'>
                        <h4>{this.props.dashboard.gameName}</h4>
                    </div>
                    <div className="cards-wrapper py-3">
                        <div className='row'>
                            <div className='col-md-4'>
                                <Card title='Total Gameplays' value={this.props.dashboard.totalGameplays} />
                            </div>
                            <div className='col-md-4'>
                               <Card title='Highscore' value={this.props.dashboard.highScore} />
                            </div>
                            <div className='col-md-4'>
                                <Card title='Total Time Played' value={this.props.gameTime.totalTime} unit='hours' />
                            </div>
                        </div>
                    </div>
                    <div className='table-wrapper py-5'>
                        <h6 className='pb-2 text-center'>Leaderboard</h6>
                        <LeaderTable />
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        dashboard: state.dashboard,
        gameTime: state.gameTime,
        loader: state.loader
    }
}

export default withRouter(connect(mapStateToProps)(Dashboard));
