import React from 'react';
import { BASE_URL } from '../constants/AppConstants';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CLOSE_ICON from '../assets/close.svg';
import EDIT_ICON from '../assets/pencil.svg';
import DELETE_ICON from '../assets/delete.svg';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
import { APIService } from '../services/ApiService';
import { connect } from 'react-redux';
import { setLoading } from '../redux/actions/common-actions';
class Configuration extends React.Component {

    constructor() {
        super();
        this.state = {
            configurations: [],
            openDialog: false
        }
        this.addNewPair = this.addNewPair.bind(this);
        this.removePair = this.removePair.bind(this);
        this.saveConfig = this.saveConfig.bind(this);
        this.editConfig = this.editConfig.bind(this);
        this.cancelEditConfig = this.cancelEditConfig.bind(this);
        this.openDialog = this.openDialog.bind(this)
    }

    componentDidMount() {
        this.initialize();
    }

    initialize() {
        this.props.dispatch(setLoading(true));
        APIService.get(BASE_URL, 'configurations')
        .then(
            (result) => {
                let _configurations = [];
                result.rows.map((config)=> {
                    if(!config.isDeleted) {
                        _configurations.push(config)
                    }
                });
                this.setState({
                    configurations: _configurations
                });
                this.props.dispatch(setLoading(false));
            }
        );
        this.state.configurations.map((config) => 
            config['isEdit'] = false
        );
    }

    handleValueChange = event => {
        const _tempConfig = [...this.state.configurations];
        _tempConfig[event.target.id][event.target.name] = event.target.value;
        this.setState({
            configurations: _tempConfig
        })
    }

    addNewPair() {
        const _tempConfig = [...this.state.configurations, {key: '', value: '', isEdit: true}]
        this.setState({
            configurations: _tempConfig
        });
    }

    removePair(index) {
        const _tempConfig = this.state.configurations;
        _tempConfig.splice(index, 1);
        this.setState({
            configurations: _tempConfig
        })
    }

    editConfig(index) {
        const _configurations = this.state.configurations;
        _configurations[index]['isEdit'] = true;
        this.setState({
            configurations: _configurations
        })
    }

    cancelEditConfig(index) {
        this.initialize();
    }

    async deleteConfig(index, config) {
        if(!config.id) {
            return;
        }
        try {
            await APIService.delete(BASE_URL,`configuration?id=${config.id}`, {})
            .then((res) => {
                this.initialize();
            })
        } catch(err) {
            console.log(err);
        }
    }

    async saveConfig(config, index) {
        let payload = {
            key: config.key,
            value: config.value
        }
        if(config.id) {
            try {
                await APIService.patch(BASE_URL, `configuration?id=${config.id}`,payload)
                .then((res) => {
                    let _configurations = this.state.configurations;
                    _configurations[index]['isEdit'] = false;
                    this.setState({configurations: _configurations});
                })
            } catch(err) {
                console.log(err);
            }
        } else {
            try {
                await APIService.post(BASE_URL, 'configuration',payload)
                .then((res) => {
                    this.initialize();
                })
            } catch(err) {
                console.log(err);
            }
        }
    }

    openDialog() {
        this.setState({openDialog: true})
    }

    closeDialog() {
        this.setState({openDialog: false})
    }

    checkSpace(e) {
        if(e.keyCode === 32) {
            e.preventDefault();
        }
    }

    render() {
        return(
            <>
                <div className="config-wrapper container py-3">
                    <TableContainer component={Paper} >
                        <Table aria-label="simple table" size="small">
                        <TableHead>
                                <TableRow>
                                    <TableCell>Key</TableCell>
                                    <TableCell align="left">Value</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                (this.state.configurations.length > 0) ? (
                                    this.state.configurations.map((configuration,index) => 
                                    <TableRow key={index}>
                                        <TableCell width={250} component="th" scope="row"> 
                                            <TextField 
                                                error={(configuration.key.length < 3) ? true : false}
                                                id={index.toString()}
                                                variant="outlined"  
                                                size="small" 
                                                name="key"
                                                value={configuration.key} 
                                                onChange={this.handleValueChange} 
                                                disabled={!configuration.isEdit}
                                                onKeyDown={this.checkSpace}
                                                helperText={configuration.key.length < 2 ? "minimum 3 characters": ""}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                        <TextField 
                                                fullWidth
                                                error={(configuration.value.lenth < 2)? true: false}
                                                variant="outlined"  
                                                size="small"
                                                id={index.toString()}
                                                name="value"
                                                value={configuration.value} 
                                                onKeyDown={this.checkSpace}
                                                onChange={this.handleValueChange} 
                                                disabled={!configuration.isEdit}
                                                helperText={configuration.value.length < 2 ? "minimum 3 characters": ""}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            {
                                                (!configuration.isEdit) ? (
                                                    <div className='d-flex align-center justify-right'>
                                                        <div className="cursor-pointer ml-2 mr-2" onClick={()=> this.editConfig(index)} >
                                                            <img height={20} src={EDIT_ICON} alt='edit' />
                                                        </div> 
                                                        <div className="cursor-pointer ml-2 mr-2" onClick={() => this.deleteConfig(index, configuration)}>
                                                            <img height={20} src={DELETE_ICON} alt='x' />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className='d-flex align-center justify-right'>
                                                        <Button className="ml-1 mr-1" variant="contained" size="small" color="primary" onClick={() => this.saveConfig(configuration, index)}>
                                                            Save
                                                        </Button>
                                                        <Button className="ml-1 mr-1" variant="contained" size="small" color="primary" onClick={()=> this.cancelEditConfig(index)}>
                                                            Cancel
                                                        </Button>
                                                        {/* <Dialog
                                                            open={this.state.openDialog}
                                                            onClose={()=>this.setState({openDialog: false})}
                                                            aria-labelledby="alert-dialog-title"
                                                            aria-describedby="alert-dialog-description"
                                                        >
                                                            <DialogTitle id="alert-dialog-title">{"Do you want to delete this pair ?"}</DialogTitle>
                                                            <DialogContent>
                                                            </DialogContent>
                                                            <DialogActions>
                                                            <Button onClick={()=>this.setState({openDialog: false})} color="primary">
                                                                Cancel
                                                            </Button>
                                                            <Button onClick={this.deleteConfig(configuration, index)} color="primary" autoFocus>
                                                                Delete
                                                            </Button>
                                                            </DialogActions>
                                                        </Dialog> */}
                                                    </div>
                                                )
                                            }
                                        </TableCell>
                                    </TableRow>
                                ) 
                            ) : null
                            }
                            </TableBody>
                            {
                                (this.state.configurations.length === 0) ? (
                                    <caption className="text-center">No records found</caption>
                                ) : null
                            }
                        </Table>
                    </TableContainer>
                    <div className='py-2 float-right'>
                        <Button variant="contained" onClick={this.addNewPair} color="primary">
                            Add new pair
                        </Button>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        loader: state.loader
    }
}

export default connect(mapStateToProps)(Configuration);