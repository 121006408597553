import React from 'react';
import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import './Login.scss';
import { APIService } from '../services/ApiService';
import { BASE_URL } from '../constants/AppConstants';
import routes from '../constants/routes.json';
import { setIsLoggedIn } from '../redux/actions/session-actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  field: {
    marginBottom: theme.spacing.unit
  },
  actions: {
    marginTop: theme.spacing.unit * 2
  }
})

class Login extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      snackbar: false,
      snackbarMsg: ''
    }
  }

  handleClose = () => {
      this.setState({
          snackbar: false
      })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  handleSubmit = event => {
    event.preventDefault();
    let payload = {
        username: this.state.username,
        password: this.state.password
    }
    APIService.post(BASE_URL, 'moodle/login',payload)
    .then(
        (res) => {
            if(res && res.token && res.userissiteadmin) {
                localStorage.setItem('token', res.token);
                this.props.history.push(routes.DASHBOARD);
                this.props.dispatch(setIsLoggedIn({isLoggedIn: true}));
            } else {
                this.state.username = '';
                this.state.password = '';
                this.setState({
                    snackbar: true,
                    snackbarMsg: 'You do not have access to this site'
                })
            }
        },
        (err) => {
            this.state.username = '';
                this.state.password = '';
                this.setState({
                    snackbar: true,
                    snackbarMsg: 'Login Failed'
                })
        }
    )
  }
  
  render() {
    const {classes} = this.props;

    return (
      <div className="h-100 d-flex justify-center align-center">
          <form className={"login-form " + classes.root} onSubmit={this.handleSubmit} noValidate>
            <Snackbar
                anchorOrigin={ {vertical:'bottom',horizontal:'center'} }
                open={this.state.snackbar}
                key={ {vertical:'bottom',horizontal:'center'} }
                onClose={this.handleClose}
            >
                <MuiAlert severity="error" onClose={this.handleClose}>
                    {this.state.snackbarMsg}
                </MuiAlert>
            </Snackbar>
            <TextField id="username" className={classes.field}
            autoFocus
            autoComplete="username"
            required
            label="Username"
            value={this.state.username}
            onChange={this.handleChange('username')}
            />

            <TextField id="password" type="password" className={classes.field}
            autoComplete="current-password"
            label="Password"
            required
            value={this.state.password}
            onChange={this.handleChange('password')}
            />

            <div className={classes.actions}>
            <Button type="submit" variant="contained" color="primary">Login</Button>
            </div>
            </form>
      </div>
    )
  }
}

function mapStateToProps(state) {
    return {
        session: state.session
    }
}

export default withStyles(styles)(withRouter(connect(mapStateToProps)(Login)));