import './App.scss';
import React from 'react';
import Routes from './routes/Routes';
import { connect } from 'react-redux';
import LoaderComponent from './components/shared/Loader';
import NavBar from './components/navbar/Navbar';
import { setIsLoggedIn } from './redux/actions/session-actions';
import Footer from './components/Footer';

class App extends React.Component {

  componentDidMount() {
    if(localStorage.getItem('token')) {
      this.props.dispatch(setIsLoggedIn({isLoggedIn: true}));
    } else {
      this.props.dispatch(setIsLoggedIn({isLoggedIn: false}));
    }
  }

  render() {
    return(
      <>
      <NavBar />
      <div className="primary-wrapper">
        <div className="loader-wrapper">
          <LoaderComponent isLoading={this.props.loader.loading} />
        </div>
        <div className="content-wrapper h-100">
          <Routes />
        </div>
      </div>
      <Footer />
      </>
    )  
  }
}

function mapStateToProps(state) {
  return {
      loader: state.loader,
      session: state.session
  }
}

export default connect(mapStateToProps)(App);
